import React from "react"

/** props */
interface Props {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
}

/** const */
const TimelineForestLakeSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      id="forest-lake"
      className="container-fluid pt-5 pb-5"
      style={{ background: "linear-gradient(#EFF2F4, #EFF2F4)", color: "#365c70" }}
    >
      <div className="container pb-5">
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)", marginTop: "10px", marginBottom: "10px" }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          {props.title}
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          {props.subtitle}
        </h4>
      </div>

      <div id="timeline">
        <div className="history-card-wrapper">
          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>01</span>
              </div>
              <h2>
                <span className="small">10,000 BC: First Humans Enter Minnesota</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The earliest humans to inhabit Minnesota were Paleo Indians. They were nomadic hunters-gatherers that
                traveled in tribes of between 20 and 50 people. They carried their belongings on their back, sought
                shelter in caves, and occasionally built crude shelters from brush and animal skin. They hunted big game
                such as mastodons, caribou, bison, and mammoths. In addition to the animals they trapped or killed, the
                Paleo Indians also ate roots, fruits, seeds, and possibly even insects. They used animal skin and plants
                for clothing.[1]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>02</span>
              </div>
              <h2>
                <span className="small">200 BC - 400 AD: Hopewell Tradition Burial Mounds</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The oldest known Native American burial mounds in Minnesota are found just west of Forest Lake in the
                Lamprey Pass Wildlife Management Area. The mounds, built in the shape of animals, were created by Native
                Americans from the Hopewell Tradition. The Hopewell tradition isn't one tribe, but rather groups of
                Native Americans who shared similar characteristics and thrived in this area.[2][3]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>03</span>
              </div>
              <h2>
                <span className="small">18th Century: Dakota and Ojibwe Tribes</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/indian-18th-century.png" alt="" />
              </div>
              <p>
                Wisconsin and Minnesota have two main tribes. The Ojibwe (Chippewa) live in large swaths of land
                surrounding the Great Lakes. The Dakota (Sioux) lived in Minnesota and the Dakotas. The Chippewa were
                pushed further west by newly arrived Europeans and other tribes. This drove them into conflict with the
                Dakota tribe. With the help of guns acquired in the fur trade, they pushed the Dakota south and west.
                Bitter battles ensued and the land around Forest Lake and White Bear Lake became hotly disputed.[4]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>04</span>
              </div>
              <h2>
                <span className="small">1825: Treaty of Prairie du Chien</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The Sioux and Chippewa disputed the land between Forest Lake and White Bear Lake. A treaty with the
                United States was signed in 1825. The treaty established boundaries between the Sioux and Chippewa.
                "passing between two lakes called by the Chippewas "Green Lakes," and by the Sioux "the lakes they bury
                the Eagles in," The boundary line ran from the creek outlet at Forest Lake, pass around the north shore,
                and then ran north into Wyoming. According to an Indian boundary marker "neither tribe paid much
                attention to the line."[5]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>05</span>
              </div>
              <h2>
                <span className="small">1837: Land Cession Treaties</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The Ojibwe and Dakota signed separate treaties that ceded the land east of the Mississippi river. These
                treaties marked the moment Forest Lake came under control of the United States instead of the Native
                Americans. The Dakota received an initial payment of $16,000 in cash and goods, and up to $40,000 per
                year for years to come. The Ojibwe received $24,000 in cash, goods and services, retaining rights to use
                the land for hunting, and fishing and other purposes.[6]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>06</span>
              </div>
              <h2>
                <span className="small">1851: Treaty of Traverse des Sioux</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The Ojibwe and Dakota signed separate treaties that ceded the land east of the Mississippi river. These
                treaties marked the moment Forest Lake came under control of the United States instead of the Native
                Americans. The Dakota received an initial payment of $16,000 in cash and goods, and up to $40,000 per
                year for years to come. The Ojibwe received $24,000 in cash, goods and services, retaining rights to use
                the land for hunting, and fishing and other purposes.[6]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>07</span>
              </div>
              <h2>
                <span className="small">1840: Minnesota Population</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The number of settlers and Native Americans living with the settlers in Minnesota was likely not more
                than 700. That included the garrison at Fort Snelling, the missionaries, and the people at the trading
                stations. The Native American population was likely much larger.[8]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>08</span>
              </div>
              <h2>
                <span className="small">1848: Land Survey</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                After the land cession treaties of 1837, surveys of the land were commissioned by the United States. The
                survey was conducted in preparation of subdividing the land and selling it to settlers moving in to the
                area. Surveying the land made it easier to locate and legally describe the parcels being purchased. The
                surveyor's notes stated the following: General Description: This township has but little variety of
                surface or soil but is one continued marsh and lakes of various sizes. The lakes have clear water and
                ground and sand banks and better some portions of this township are one extensive marsh and swamp
                singularly intermixed. Particularly the southeast part.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>09</span>
              </div>
              <h2>
                <span className="small">1853: Territorial Road Authorization</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                Early roads were simple trails meandering across the countryside. The United States Congress
                appropriated money for a military road between Hastings to Superior Wisconsin. Leaders in St. Paul and
                Minneapolis (originally called St. Anthony) considered this road a threat to businesses. So the
                Minnesota Territorial Legislature authorized the construction of a road from St. Paul and Minneapolis to
                Taylors Falls. The road followed Lake Drive through Columbus to Kettle River Boulevard to Forest Lake
                and headed north.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>10</span>
              </div>
              <h2>
                <span className="small">1854: Stagecoach Line</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1854-stagecoach.png" alt="" />
              </div>
              <p>
                The stagecoach was an early form of public transportation. A stagecoach line was established from St.
                Paul to Columbus to Wyoming and up to Duluth. The road quality was poor. It twisted and turned, avoiding
                large trees and swampy areas. Narrow bridges were built over streams. In the summer, the roads were
                dusty and bumpy. The fare was between 3 to 15 cents per mile and the coaches traveled between 4 to 12
                miles per hour. A hotel was built in Columbus near the intersection of Lake Drive and Kettle River
                Boulevard. There was a large sawmill, school, church, post office, and store. Columbus fought to be the
                Anoka County seat but it lost by three votes. Source: Reflections of Forest Lake.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>11</span>
              </div>
              <h2>
                <span className="small">1855: First Land Purchase</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1855-patent.png" alt="" />
              </div>
              <p>
                The first person to purchase land in Forest Lake was James Stinson. But according to Minnesota census
                records he may not have lived in the area until around 1895. Louis Schiel and his family were the first
                to settle in Forest Lake. His father was a piano maker in Heilbrun, Germany. In 1844 troubles began in
                Germany. Louis identified himself as a revolutionist and as a result he was forced to leave his country.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>12</span>
              </div>
              <h2>
                <span className="small">1859: First School Teacher</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1859-teacher.png" alt="" />
              </div>
              <p>
                Mary Poston was the area’s first school teacher. She instructed children in a log cabin just northeast
                of Forest Lake. The land for the school was purchased from Isaac Banta for 50 cents. The school served
                28 students. Source: Forest Lake Area Schools History.[9]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>13</span>
              </div>
              <h2>
                <span className="small">1861: Civil War Begins</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1861-scott.png" alt="" />
              </div>
              <p>
                Dred Scott, a slave, temporarily called Minnesota home before the Civil War. Minnesota was a free state.
                This was an important detail in the landmark Supreme Court decision which further drove the United
                States to war.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>14</span>
              </div>
              <h2>
                <span className="small">1868: Railroad Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1868-railroad.png" alt="" />
              </div>
              <p>
                The first train traveled on the newly laid railroad tracks from St. Paul to Wyoming. It was a
                wood-burning locomotive with a high funnel-style smokestack trimmed in shiny brass. The railroad
                officials decided to put a train stop and plat the village of Forest Lake. It was a natural decision
                because of the area’s beautiful setting of lakes and dense forests. The village was named Forest Lake
                because of the heavy timber skirting the shores of the lake. The streets were designed to run north and
                south. Avenues were laid out from east and west. Blocks and lots were all platted from First Ave North
                to Eleventh Avenue South.[10]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>15</span>
              </div>
              <h2>
                <span className="small">1868: The Famous Marsh Hotel</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1868-hotel.png" alt="" />
              </div>
              <p>
                Michael Marsh built a resort hotel, post office, mercantile store, and boat landing on the northwest
                shore of Forest Lake. This area became a famous resort entertaining guests from all around the world.
                Among its visitors were presidents McKinley and Cleveland. By the 1880s the hotel had 75 rooms. Boats
                could be rented for $1.50 per day. Excursions could be taken on the steamboat Germania. Dining was
                served on china that had a pale green border with gold trim. It was a fabulous hotel built by a
                progressive gentleman. Sadly, Michael Marsh died in 1891 and the three main buildings of the resort
                burned down two years later.[11]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>16</span>
              </div>
              <h2>
                <span className="small">1873: School District Organized</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1873-school-district.png" alt="" />
              </div>
              <p>
                Forest Lake organized school district number 56 and built a one-room school built near the intersection
                of Highway 61 and Broadway Avenue. A large wood burning stove surrounded by a heat shield kept the
                classroom warm. The student’s lunch boxes where placed around the stove to keep their food warm. School
                was from October 1st to April 1st. The teacher’s wage was $300 for seven months.[12]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>17</span>
              </div>
              <h2>
                <span className="small">1874: First General Store</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                Ole and August Alm opened the first general store and sold it three years later to John Koller. The
                Marsh Hotel bought large quantities of supplies. One pound of coffee was sold for 25 cents. Fifteen
                pounds of nails sold for 60 cents. A half-pound of tea was sold for 30 cents. The inventory was
                eventually sold to J.L. Simmons which was the beginning of the Simmons Store. This store became a
                downtown landmark. The Chippewa Native Americans brought their handmade pottery, bead-trimmed moccasins,
                and other garments to trade. These items were often sold to summer visitors.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>18</span>
              </div>
              <h2>
                <span className="small">1892: Houle Grocery</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1892-houle.png" alt="" />
              </div>
              <p>
                Joseph W. Houle and Pris Peloquin sold groceries at their store. They could deliver grocery orders to
                your home by horse-drawn carts. At the store, clerks would fill orders by pulling items from wooden
                shelves in the back. They would then bring them to the front counter and package them. The bill was
                added by hand and rung up on a big cash register.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>19</span>
              </div>
              <h2>
                <span className="small">1893: The Lost City of Garen</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1893-garen.png" alt="" />
              </div>
              <p>
                South of Forest Lake at the intersection of Highway 61 and 190th Street is what once was the downtown of
                community call Garen. As part of a settlement for accidentally burning part of the local peat bog, the
                railroad agreed to add a train station. Although Garen was not a scheduled stop, the train could be
                flagged down and passengers could purchase a ticket to Forest Lake for 15 cents. Garen had a convenience
                store, taverns, the "Half Way Inn" and its own school district. Garen School District 72 was started in
                1893. The school district was closed in 1934. Today there are no visible signs of the community of Garen
                but it hasn’t been forgotten by the residents who once lived there.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>20</span>
              </div>
              <h2>
                <span className="small">1893: Forest Lake Becomes a Village</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1893-creamery.png" alt="" />
              </div>
              <p>
                Many early residents desired to incorporate into a village. The incorporation papers stated, "The
                quantity of lands embraced in said proposed village as 1,221.75 acres. The name they desired for the
                proposed village was Forest Lake. The number of persons actually residing in said territory was One
                Hundred Seventy Five (175), ascertained by a census taken on May 10, 1893. A petition for incorporation
                was signed by 38 gentlemen." An election would be held on July 10, 1893 at J.L. Simmons store. Without
                modern forms of advertisement to spread the word of the election, three copies of the petition were
                posted in the five most public places in town. On election day, the polls opened at 9 AM and closed at 5
                PM. There were 36 ballots cast. They were all in favor of the incorporation.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>21</span>
              </div>
              <h2>
                <span className="small">1896: Rural Mail Delivery Service Starts</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1896-post-office.png" alt="" />
              </div>
              <p>
                Picking up the mail at the post office was a daily ritual for the people of Forest Lake. The post office
                was a busy place early in the morning as business people picked up the mail before opening their stores.
                Rural mail delivery service started in 1896. For the first time, farmers could get their daily mail
                delivered to them.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>22</span>
              </div>
              <h2>
                <span className="small">1897: Wire Grass</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                For centuries, the marsh plant called wire grass had no practical use. It has no practical use today.
                But for about 40 years it was used to create colorful rugs that were very popular. The grass was sharp
                enough to cut a finger and it grew in abundance west of Forest Lake. The Crex Carpet Company built 3
                camps. Camp 1 was west of Wyoming. Camp 2 was south of that and camp 3 was west of Forest Lake. Camp 3
                Road, which starts at the intersection of Kettle River Boulevard and Lake Drive, is the road that lead
                to the 3rd camp. Hundreds of men worked in the bogs harvesting the grass for 10-12 hours a day. They
                earned $35 a month and received room and board.[13]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>23</span>
              </div>
              <h2>
                <span className="small">1899: First Telephones</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The first telephones arrived in Forest Lake with a new long-distance line installed by Charles Avery and
                Fred Murray. These telephones were first located in public places like hotels. The telephone took a big
                step forward in 1904 when a one-position magneto switchboard was installed in the rear of the J.L.
                Simmons Dry Goods Store. Operators assisted customers in the store until a loud bell rang which
                indicated that someone wanted to make a call. Then they would manually connect the caller to the home
                they wanted to call using wires on the switch board. This was the day of part lines. Each house had a
                different ring. All the houses on the party line would hear the ring. One long ring and two short rings
                may indicate that the caller wanted to call the Smith’s house. This system allowed neighbors to easily
                eavesdrop on each other’s calls.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>24</span>
              </div>
              <h2>
                <span className="small">Early 20th Century: Ice House</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                Prior to the invention of the air conditioner and freezer, ice was used to keep things cold. An ice
                house was built on the shore of Forest Lake at the present site of the downtown boat launching area.
                When the water in the lake froze to a depth of 22 inches, the ice was cut into 20-by-36-inch slabs. It
                was floated down a channel and hoisted into the ice house. In 1953, 8,500 slabs were harvested. The ice
                was insulated with 12 inches of saw dust and delivered to people’s homes the following summer. A home
                ice box would be kept filled for $3/month. With new inventions the need for ice declined and the ice
                house was closed in the 1940s.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>25</span>
              </div>
              <h2>
                <span className="small">Early 20th Century: Police</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Early police would walk the city streets. At night they would light the kerosene street lamps and make
                certain the shop doors were locked. Marshal Nels Kloster recalls that many nights he discovered a
                business who left their doors unlocked. In 1915, the salary for a marshal was $60/month. One marshal in
                the 1910s encountered bank robbers. The robbers tied the marshal up, put an apple in his mouth, and
                locked him in a boxcar. They proceeded to rob a Forest Lake bank. The following morning the marshal was
                found. Forest Lake had a jail which was a small brick building. The jail had a lock on the outside and
                one cell on the inside. This was usually used for someone to "sleep it off" or heal up after a good
                fight. This former jail was located two blocks north of the Broadway Ave and Highway 61 intersection.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>26</span>
              </div>
              <h2>
                <span className="small">1903: Forest Lake Times Publishes First Paper</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Originally called "The Enterprise", the Forest Lake Times was first published by Howard Folsom. Folsom
                published only seven issues before he sold it. The paper was sold several times and there was even a
                time where the paper ceased publication. The Forest Lake Times was officially named that in 1916.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>27</span>
              </div>
              <h2>
                <span className="small">1903: First Bank Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1903-bank.png" alt="" />
              </div>
              <p>
                The Forest Lake State bank was the first bank of Forest Lake. It was opened by O.E. Struble and Wayne
                Struble. The bank had its ups and downs. In around 1920, farmers income dropped and it was difficult for
                them to repay their loans. In 1929 the stock market crashed and the Great Depression followed. The bank
                made many loans on good faith to help tide people over. Many people lost their farms, homes, and
                businesses due to the poor economy.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>28</span>
              </div>
              <h2>
                <span className="small">1908: First Automobile</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The first car owned by someone in Forest Lake may have been Dr. J.A. Poirier’s 1908 two-cylinder Reo
                made by the R.E. Oldsmobile Company. It traveled 50 miles per hour. The deep ruts in the dirt roads
                prevented him from going that fast. The car had two forward speeds and one reverse speed.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>29</span>
              </div>
              <h2>
                <span className="small">1914: Forest Lake Theater</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1914-theater.png" alt="" />
              </div>
              <p>
                In the early days, the theater would show silent black and white films every Wednesday and Sunday
                evening. A film might show a train speeding to the heroine who had be tied up by the villain. Could the
                hero save her in time? You would have to attend next week to find out. A piano player would play music
                to accompany a film. This was very important to make the film come alive because the film had no audio.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>30</span>
              </div>
              <h2>
                <span className="small">1917: Electricity Comes to Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1917-appliances.png" alt="" />
              </div>
              <p>
                With electricity came a demand for new lights and appliances. At that time, a license was not required
                to wire a house. Rooms would be installed with one bare light bulb hanging from the ceiling. Washing
                machines, electric stoves, and new heating systems rapidly changed many homes.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>31</span>
              </div>
              <h2>
                <span className="small">1917: World War I</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1917-legion.png" alt="" />
              </div>
              <p>
                In April 6, 1917, the United States declared war on Germany. The local men, like Frank Anderson,
                enlisted in the military. Frank was a message carrier who crawled between trenches with memorized
                messages. Sometimes he carried written messages. If he was caught, his orders were to swallow the paper.
                The war ended on November 11, 1918. After the war, the American Legion was formed to promote patriotism.
                Forest Lake Post 255 was chartered in 1919. One of the most enduring contributions of Post 255 is the
                yearly Forest Lake 4th of July parade.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>32</span>
              </div>
              <h2>
                <span className="small">1919: Sewer & Water Lines Installed</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Forest Lake’s first water service started in 1919 and sewer service started in 1920. Bathrooms and
                kitchens with running water became popular. Gone were the days of bathing in a galvanized tub in the
                kitchen.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>33</span>
              </div>
              <h2>
                <span className="small">1922: Fire Department Organized</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                To fight fires, the residents used a water bucket brigade, a hand pumper, and a hose cart. But that
                wasn’t enough to adequately fight fires. On June 5, 1922 the mayor called the first Fire Department
                meeting to order and they appointed Tom Rolseth as fire chief. One year later, the hottest explosion and
                fire in the history of Forest Lake was set ablaze. Gasoline was being unloaded from a railroad car and
                some seeped out. When the motor was turned off, a spark ignited the gas. 5,300 gallons of gas, 1,500
                gallons of kerosene, and 4,000 gallons of lubrication oil exploded that day.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>34</span>
              </div>
              <h2>
                <span className="small">1929: The Great Depression</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1929-depression.png" alt="" />
              </div>
              <p>
                The stock market crashed in 1929 and the United States had the largest economic downturn in history. GDP
                fell and unemployment rose. Many citizens felt the pains of the depression. They grew gardens in their
                backyard. Hunting and fishing became an important source of food. Businessmen extended credit to their
                customers as long as they could. A popular saying during this time was "make it do, pass it on, and wear
                it out." Franklin Delano Roosevelt became the 32nd president in 1933 and rolled out programs to help
                move the country out of the depression. The programs built four large buildings in the Carlos Avery Game
                Farm and the football field at the school.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>35</span>
              </div>
              <h2>
                <span className="small">1929: Forest Lake Tornado</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1929-tornado.png" alt="" />
              </div>
              <p>
                At about 6 PM, the unforgettable roar of a tornado was heard in Forest Lake. The powerful funnel touched
                down about one half mile northeast of the 35W & 35E junction. The tornado was powerful enough to destroy
                many buildings, toss fish out of water, pluck all the feathers from the chickens, and drive straw into
                telephone poles. Rev. N.L. Frank wrote, "Etched into my mind is the recollection of the floor of a
                house, superstructure all gone, floating on the lake. There in the middle of the floor sat a shiny
                piano, nothing else." One man died, a bachelor who moved to the area just three days prior.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>36</span>
              </div>
              <h2>
                <span className="small">1930: Notorious Criminals in Forest Lake</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1930-forest-lake-criminals.png" alt="" />
              </div>
              <p>
                Forest Lake was a popular destination for national criminals. "Ma" Baker was the mother of several
                criminals who ran the Banker gang. Director of the FBI, J. Edgar Hoover described her as "the most
                vicious, dangerous and resourceful criminal brain of the last decade." She and her gang lived in a cabin
                behind Lake Street. "Baby Face" Nelson was a notorious bank robber who was responsible for killing more
                FBI agents in the line of duty than any other person. He owned a cabin in Forest Lake. Bugs Moran was a
                bootlegger that continued to pose a significant threat to Al Capone. Bugs Moran lived on North Shore
                Drive.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>37</span>
              </div>
              <h2>
                <span className="small">1930: 4th of July Parade</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1930-parade.png" alt="" />
              </div>
              <p>
                The 4th of July of Parade is the largest, yearly, crowd-drawing event in Forest Lake. The parade started
                at 10:00 am and the first-place float would win $15. After the parade, people assembled at the beach to
                listen to a political speaker. At 1:00 PM the chairmen would start the races. There was the Fat Man’s
                Race for those heavier than 200 pounds. The winner would get a box of cigarettes and a can of pork and
                beans. There was a tug of war that drew a crowd of the area’s strongest men. A popularity contest was
                held to find out who was the most popular girl. The first-place girl won $50. The night wrapped up with
                a spectacular display of fireworks over the lake.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>38</span>
              </div>
              <h2>
                <span className="small">1931: Forest Lake School Song Written</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1931-school.png" alt="" />
              </div>
              <p>
                The words to the school song were passed out to students for the first time. The students sang "We’re
                loyal to you, Forest Lake" with enthusiasm and pride especially when it was played for the first time at
                the basketball games.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>39</span>
              </div>
              <h2>
                <span className="small">1932: Death of a Policeman</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                In 1932 there was a gas station that stood two blocks south of Broadway Avenue and Highway 61
                intersection. Nels Berglin, the night marshal, stopped in at the station. A black 1929 Ford pulled up to
                the pumps for gas. There were three young adults in the car. They bought $1 of gas. The attendant took
                the money and walked into the station. Two of the men followed the attendant into the store with guns
                drawn. They shouted, "This is a stickup. Lay down on the floor. We mean business!" Nels Berglin felt it
                was his duty to stop the robbery even against all odds. He started removing his glove from his right
                hand so he could use his revolver. The robbers fired four shots at him. He fired two shots in return.
                The noise in the small room was deafening. After the shooting, one of the robbers said, "Let’s get the
                hell out of here." They quickly drove off with nothing to show for it other than one dead policeman. The
                robbers were later captured in relation with other robberies.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>40</span>
              </div>
              <h2>
                <span className="small">1933: William Hamm Kidnapping</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                William Hamm Jr., the president of Hamm’s Brewing Co., was kidnapped by the Barker-Karpris gang while
                walking home on his lunch break. At 12:45 Hamm left his office and was stopped. A man reached to shake
                his hand asking, "You are Mr. Hamm, are you not?" Hamm was forced into a waiting car and was driven to a
                hideout in Benseville, Illinois. The gang demanded a $100,000 ransom. After the ransom was paid, Hamm
                was released on June 19th in a farm field north of Forest Lake. Lu Heim, chief telephone operator in
                Forest Lake, received a call from a man saying, "I’m Billy Hamm, and I’ve just been released. Could you
                please call my family?" (Page 95.) The gang was able to stay ahead of the police by corrupting the head
                of St. Paul’s kidnap squad, Thomas A. Brown, with a $25,000 bribe. He was dismissed from the force, but
                never prosecuted. Six months later the gang kidnapped Edward Bremer for a $200,000 ransom. That turned
                out to be a big mistake because the Bremer family was friends with President Franklin Delano Roosevelt,
                who unleashed the full force of the FBI on the gang. Using the serial numbers of the cash used to pay
                the ransom, the FBI was able to track down and prosecute the gang members.[14]
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>41</span>
              </div>
              <h2>
                <span className="small">1939: World War II</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                This was an era of tearful goodbyes as Forest Lake men between the ages of 18 to 45 were drafted and
                sent overseas. Everyone had to do their part for the war effort. Ration books were needed to purchase
                sugar, coffee, meat, and butter. Posted speed limits of 35 miles per hour were common to prolong the
                life of tires. For the duration of the war no radios, refrigerators, washing machines, or cars were
                manufactured. Forest Lake was considered to be vulnerable in case of a bombing attack because of the
                proximity to war plants. At 10 PM on a specific evening in 1942 the first practice blackout occurred. No
                light was visible in Forest Lake. Homeowners covered their windows with "blackout curtains." Wardens
                patrolled the streets. Not a single light was visible.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>42</span>
              </div>
              <h2>
                <span className="small">1940: First Bowling Alley</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1940-bowling.png" alt="" />
              </div>
              <p>
                Frank Wagner started an eight-lane bowling alley. Young men had jobs as pin setters and were paid two
                cents a line. Bowlers paid 30 cents a line. Bowling was very important during these years. Some bowlers
                traveled from as far away as Las Vegas to participate in tournaments.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>43</span>
              </div>
              <h2>
                <span className="small">1941: First Library Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1941-hall.png" alt="" />
              </div>
              <p>
                The women of the American Legion Auxiliary Post 225 fought to overcome obstacles to provide a library
                for public use. These women persuaded the Forest Lake Village Council to use part of the south room of
                the village hall for a library. Fund raisers were used to raise money for the books. The Boy Scouts
                collected books the citizens wanted to donate to the library. After opening the room had bouquets of
                flowers and coffee was donated and served to the guests as they tasted homemade donuts donated by local
                women. In 1961, after 16 years as a volunteer, Wilma Engler became the first paid volunteer with a
                monthly salary of $75.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>44</span>
              </div>
              <h2>
                <span className="small">1948: Highway 61 Widened</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1948-main-street.png" alt="" />
              </div>
              <p>
                Cars used to drive bumper-to-bumper through Forest Lake, especially in the summer. To reduce the
                congestion, the highway department decided to widen the road. Homeowners wondered what would happen to
                their neighborhood. Houses were removed and the area looked like a disaster zone. There were basements
                with no tops and sidewalks leading to nowhere. The road was completed and on January 21, 1949, Dr. C.M
                Niles woke up to a loud crash. A large truck missed the turn on highway 61 and drove in to the doctor’s
                house. The doctor was thrown out of the house onto the snow. He suffered a broken hip and shock.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>45</span>
              </div>
              <h2>
                <span className="small">1950: Dial Phones Replace Operators</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                A new technology allowed people to directly call someone without needing to speak to an operator. At
                10:07 pm, Mayor Hector Pepin dialed the first call.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>46</span>
              </div>
              <h2>
                <span className="small">1954: Stripe Drive-In</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Lee Sandager and Fran LaBelle started a small red and white striped drive-in restaurant. People were
                served hamburgers, fries, and malts and could eat them in their car. The servers were called "car hops"
                and dressed in red culottes and white blouses.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>47</span>
              </div>
              <h2>
                <span className="small">1955: Skateland</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1955-skateland.png" alt="" />
              </div>
              <p>
                Bert and Elise Vogel opened a new year-around roller skating rink. The facility was a popular
                destination for teenagers. It also hosted many show skating acts like Magic Toy Shop, Friendly Freddie,
                Arabs and the Camel, Square Dancing on Skates, and Tom Sawyer Gang.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>48</span>
              </div>
              <h2>
                <span className="small">1958: Hub Drive-in Theater</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Mr. and Mrs. Bob Drummond and Everette Struble opened the Hub Drive-In Theater. It was a fun theater
                where families often came to watch a movie on the big screen from the comfort of their car. This was a
                new place to take a date. Wednesday nights were busy as teenagers were admitted for only $1.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>49</span>
              </div>
              <h2>
                <span className="small">1969: Train Station Closes</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                After running for 101 years, the Forest Lake train station closed. It was later torn down in 1971 and
                the train tracks were removed in 1991.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>50</span>
              </div>
              <h2>
                <span className="small">1969: Interstate 35 Completed</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3"></div>
              <p>
                The interstate immediately brought great change to Forest Lake. What once was a familiar neighborhood
                area became new commercial businesses. Travel patterns changed which caused a loss of business to the
                businesses on Lake Street. The city’s growth was along the interstate. New businesses sprung up
                including an industrial park, apartment buildings, mall-type construction, and car dealers.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>51</span>
              </div>
              <h2>
                <span className="small">1974: Forest Lake Becomes a City</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1974-forest-lake-becomes-city.png" alt="" />
              </div>
              <p>
                The Minnesota State Legislature changed villages to cities throughout the state including Forest Lake.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>52</span>
              </div>
              <h2>
                <span className="small">1978: Forest Lake Area Radio Station</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                WLFX, the Forest Lake Area radio station, went live at noon with the message, "Good Afternoon. This is
                the first official broadcast of WLKX FM coming to you from Forest Lake, Minnesota." The National Anthem
                followed. The programming included news, weather, a Pet Patrol segment, and religious programming on
                Sunday morning.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>53</span>
              </div>
              <h2>
                <span className="small">1985: Lakes Area Community Television Founded</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                The joint powers of the City of Forest Lake, Forest Lake Township, Columbus Township, and Scandia
                founded the Lakes Area Community Television to encourage local area residents to produce local cable
                television for and about the community. It televises school board meetings, city council meetings,
                interviews with sports figures, music contests, variety shows, and more.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>54</span>
              </div>
              <h2>
                <span className="small">1987: First Police Station</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1987-wagner.png" alt="" />
              </div>
              <p>
                The first police station was built as part of a new city complex. The city complex also included the
                council chambers, offices, and a library. Before the police filled out traffic accident reports and did
                other reports in their cars or was taken home. To call the police a person would need to call the
                Wagner’s Hamburger Shop which was open 24 hours per day. The phone number was 303. It was the 911 of
                its' day.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>55</span>
              </div>
              <h2>
                <span className="small">1987: Marching Band in Rose Parade</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1987-band.png" alt="" />
              </div>
              <p>
                The Forest Lake Marching Band was invited to play in the Rose Parade in Pasadena, California. This
                televised event was very exciting to the students. To raise money for travel expenses, a "Pounds for
                Pasadena" fundraiser was held. Every pound that was weighed in would raise 2 cents. Crowds watched as
                the Forest Lake firefighters weighed in with their full gear. People held bowling balls and budging
                suitcases. One man held a canoe over his head. The crowd pleaser was when four large men stood together
                with added weight and weighed in at 2600 pounds. A total of $9,107 was raised for 455,000 pounds.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>56</span>
              </div>
              <h2>
                <span className="small">1988: Senior Community Center Opens</span>
              </h2>
            </div>
            <div className="timline-text">
              <div className="mb-3">
                <img src="/history/forest-lake/1988-senior-center.png" alt="" />
              </div>
              <p>
                The community pulled together with hours of volunteer work and large and small donations to make the
                senior center a reality. In the center, people gather together to enjoy meals, play cards, quilt, paint
                and more.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-right">
            <div className="head">
              <div className="number-box">
                <span>57</span>
              </div>
              <h2>
                <span className="small">1993: Centennial Event</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                The City of Forest Lake came together to celebrate 100 years. The events included a historical museum,
                sports tournaments, art fair, fishing contest, water ski show, craft fair, and more.
              </p>
            </div>
          </div>

          <div className="history-card history-card--step4" data-aos="fade-left">
            <div className="head">
              <div className="number-box">
                <span>58</span>
              </div>
              <h2>
                <span className="small">2001: Forest Lake Township Annexation</span>
              </h2>
            </div>
            <div className="timline-text">
              <p>
                Before 2001, the City of Forest Lake and the Forest Lake Township were different. There was a decade
                long debate on whether the City of Forest Lake should annex Forest Lake Township. The township didn’t
                want to be annexed. One of the key disagreements was on taxation. The township had about 50% lower taxes
                than the city. After multiple court appeals, the Minnesota court system upheld the annexation.[15]
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container">
          <div>
            <strong>Sources:</strong>
          </div>
          <ol>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Goodman, Robert, et al. A history of Washington County: Gateway to Minnesota History. Washington County
              Historical Society, 2008. p. 12.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Lamprey Pass Wildlife Management Area. Minnesota Department of Natural Resources.{" "}
              <a href="http://www.wildlifeviewingareas.com/wv-app/ParkDetail.aspx?ParkID=566" target="_blank">
                http://www.wildlifeviewingareas.com/wv-app/ParkDetail.aspx?ParkID=566
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Habitat Preservation Projects. Minnesota Department of Natural Resources.{" "}
              <a href="http://www.dnr.state.mn.us/eco/nongame/land_preservation/projects.html" target="_blank">
                http://www.dnr.state.mn.us/eco/nongame/land_preservation/projects.html
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Ojibwe Indians. Michigan State University.{" "}
              <a href="http://geo.msu.edu/extra/geogmich/ojibwe.html" target="_blank">
                http://geo.msu.edu/extra/geogmich/ojibwe.html
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>Treaty with the Sioux, etc. 1825.</li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              1837 Land Cession Treaties with the Ojibwe & Dakota.{" "}
              <a href="http://treatiesmatter.org/treaties/land/1837-ojibwe-dakota" target="_blank">
                http://treatiesmatter.org/treaties/land/1837-ojibwe-dakota
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Treaty of Traverse des Sioux, 1851.{" "}
              <a href="http://www.mnopedia.org/event/treaty-traverse-des-sioux-1851" target="_blank">
                http://www.mnopedia.org/event/treaty-traverse-des-sioux-1851
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Folwell, William Watts. A history of Minnesota. 1922. p. 351.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Forest Lake Area Schools: Celebrating 100 Graduating Classes. 2011.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Warren Upham. Minnesota Geographic Names Their Origin and Historic Significance. Minnesota Historical
              Society Saint Paul, 1920 p. 569.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Goodman, Robert, et al. A history of Washington County: Gateway to Minnesota History. Washington County
              Historical Society, 2008. p. 236.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Neil, Edward. History of Washington County and the St. Croix Valley Including the Explorers and Pioneers
              of Minnesota. North Star Publishing Company, 1881. p. 467.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Crex Carpet Company.{" "}
              <a href="http://www.mnopedia.org/group/crex-carpet-company" target="_blank">
                http://www.mnopedia.org/group/crex-carpet-company
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Peck, Lauren. Abducted in St. Paul! 21 June 2016.{" "}
              <a href="http://www.mngoodage.com/voices/mn-history/2016/06/abducted-in-st-paul/" target="_blank">
                http://www.mngoodage.com/voices/mn-history/2016/06/abducted-in-st-paul/
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              Howard, Ryan. Does Forest Lake serve its residents well? 4 June 2015.{" "}
              <a
                href="http://www.hometownsource.com/forest_lake_times/news/local/does-forest-lake-serve-its-residents-well/article_599c1834-282e-5bf3-befe-f5303ba98245.html"
                target="_blank"
              >
                http://www.hometownsource.com/forest_lake_times/news/local/does-forest-lake-serve-its-residents-well/article_599c1834-282e-5bf3-befe-f5303ba98245.html
              </a>
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              The pictures used above came from the following sources: Washington County Historical Society, Alamy stock
              photos, and the Forest Lake Area Historical Society.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              A special thanks is given to the following source. It was used throughout this timeline. This book is a
              great source to learn more about the history of Forest Lake. Vogel, Elsie. Reflections of Forest Lake.
              Forest Lake, MN, Forest Lake Centennial Association, Inc., 1993.
            </li>
            <li style={{ wordWrap: "break-word", marginBottom: "20px" }}>
              A second special thanks is given to the City of Forest Lake who provided further information about the
              history.
            </li>
          </ol>
        </div>
      </div>
    </section>
  )
}
/** export */
export default TimelineForestLakeSection
