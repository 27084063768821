import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"
import TimelineForestLakeSection from "../../components/dynamic-sections/TimelineForestLakeSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const ForestLakeHistoryPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/history/forest-lake/",
          name: "Forest Lake History | Norhart",
          image: `${config.siteMetadata.siteUrl}/history/forest-lake/forest-lake-history-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Forest Lake City History | Norhart"
      description="Forest Lake has quite a historical past. It was a resort destination for presidents, mobsters, and more! Read all about the fascinating story of Forest Lake and how it all started."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/history/forest-lake/forest-lake-history-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/history/forest-lake/forest-lake-history-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Forest Lake, MN"
        subtitle="Experience Forest Lakes Rich History"
        imageTitle="Experience Forest Lakes Rich History"
        image="/history/forest-lake/forest-lake-history-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Norhart And Forest Lake"
        tagLine="We build more than just apartments, we improve lives"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TimelineForestLakeSection
        icon={NorhartDarkCircleIcon}
        iconTitle="History Timeline"
        title="Forest Lakes Grand History"
        subtitle="Forest Lake has quite a historic past. It was a resort destination for presidents, mobsters, and more! Read all about the fascinating story of Forest Lake and how it all started."
      />

      <AwardsSection
        header="Forest Lake Apartments"
        title="We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ForestLakeHistoryPage
